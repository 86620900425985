//file: src/webpages/index.js
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  // Switch,
  Route,
  // Link,
} from "react-router-dom";

import Layout from "../components/Layout";

import Home from "./home";
import Contacts from "./contacts";
import Chat from "./chat";
import Maps from "./maps";
import Profile from "./profile";
import AuthRedirect from "./authredirect";

function Webpages() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/maps" element={<Maps />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/authredirect" element={<AuthRedirect />} />
        </Routes>
      </Layout>
    </Router>
  );
}
export default Webpages;
