//file: src/webpages/chat.js
import React, { useEffect } from "react";

function AuthRedirect() {
  useEffect(() => {
    document.title = "Fortico - AuthRedirect";
  });

  // The page is reloaded after authentication. This catches the JWT and saves it for later use.
  try {
    const queryParams = new URLSearchParams(window.location.search);
    const fortico_access_token = queryParams.get("access_token");
    if (
      fortico_access_token !== null &&
      typeof fortico_access_token !== "undefined"
    ) {
      localStorage.setItem("fortico-access-token", fortico_access_token);
    } else {
      localStorage.removeItem("fortico-access-token");
    }
    window.location.replace(process.env.REACT_APP_API_URL_PROFILE);
  } catch (e) {}

  return (
    <div>
      <h1>Fortico</h1>
      <p>This is the AuthRedirect page.</p>
    </div>
  );
}
export default AuthRedirect;
