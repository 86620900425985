//file: src/webpages/home.js
import React from "react";

function Home() {
  return (
    <div>
      <h1>Fortico</h1>
      <p>This is the home page</p>
      <p>
        Certain services, while visible, will not work until authorised by the
        administrator.
      </p>
    </div>
  );
}
export default Home;
