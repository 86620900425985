//file: src/components/Footer.js
import React, { useState, useEffect } from "react";
const axios = require("axios");

const initialState = {
  apiStats: [],
};

function Footer() {
  const [state, setstate] = useState(initialState);

  // This function asks the API for the version number and description
  function api() {
    try {
      axios.get(`${process.env.REACT_APP_API_URL_API}`, {}).then((response) => {
        setstate((previousState) => {
          return {
            ...previousState,
            apiStats: response.data,
          };
        });
      });
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    api();
  }, []); // Empty array means "only run on the first render"

  return (
    <footer>
      API {state.apiStats.api} {state.apiStats.title} {state.apiStats.body}
    </footer>
  );
}
export default Footer;
