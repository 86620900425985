import "./App.css";
import Webpages from "./webpages";

import "./styles/style.css";

function App() {
  return (
    <div>
      <Webpages />
    </div>
  );
}
export default App;
