import React, { useState, useEffect } from "react";
const axios = require("axios");
var Chance = require("chance");
var chance = new Chance();
const { v4: uuidv4 } = require("uuid");

const initialState = {
  contacts: [],
};

export default function Contacts() {
  const [state, setstate] = useState(initialState);

  useEffect(() => {
    document.title = "Fortico - Contacts";
  });

  // Helper function
  function isLoggedIn() {
    return localStorage.getItem("fortico-access-token") && true;
  }

  // This function is called when the logged in state changes
  // If we're logged in, populate the contact list
  // If we're logged out, reset the contact list
  function listContacts() {
    if (isLoggedIn()) {
      axios
        .get(`${process.env.REACT_APP_API_URL_CONTACTS}`, {
          headers: {
            "fortico-access-token": localStorage.getItem(
              "fortico-access-token"
            ),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          // withCredentials: true,
        })
        .then((response) => {
          setstate((previousState) => {
            return {
              ...previousState,
              contacts: response.data,
            };
          });
        });
    } else {
      setstate((previousState) => {
        return {
          ...previousState,
          contacts: [],
        };
      });
    }
  }

  // This function retrieves the details of a given contact
  // function readContact(contactId) {
  //   let id = contactId;
  //   axios
  //     .get(`${process.env.REACT_APP_API_URL_CONTACTS}`, {
  //       headers: {
  //         "fortico-access-token": localStorage.getItem("fortico-access-token"), //{"Authorization" : `Bearer ${token}`} }
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //       // withCredentials: true,
  //       params: {
  //         id: `${id}`,
  //       },
  //     })
  //     .then((response) => {
  //       setstate((previousState) => {
  //         return {
  //           ...previousState,
  //         };
  //       });
  //     });
  // }

  // This function creates a new contact with random details
  // Mainly used for testing
  function createRandomContact(e) {
    const newContact = {
      email: chance.email(),
      name: chance.first(),
    };
    if (isLoggedIn()) {
      axios
        .post(`${process.env.REACT_APP_API_URL_CONTACTS}`, newContact, {
          headers: {
            "fortico-access-token": localStorage.getItem(
              "fortico-access-token"
            ), //{"Authorization" : `Bearer ${token}`} }
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          // withCredentials: true,
        })
        .then((response) => {
          let updatedState = [...state.contacts];
          updatedState.push(response.data);
          setstate((previousState) => {
            return {
              ...previousState,
              contacts: [...updatedState],
            };
          });
        });
    } else {
      let updatedState = [...state.contacts];
      newContact.id = uuidv4();
      updatedState.push(newContact);
      setstate((previousState) => {
        return {
          ...previousState,
          contacts: [...updatedState],
        };
      });
    }
  }

  // This function creates a new contact with the provided details
  function createContact(e) {
    e.preventDefault();
    const form = document.forms["createContact"];
    const newContact = {
      email: form.elements["email"].value,
      name: form.elements["name"].value,
    };
    if (isLoggedIn()) {
      axios
        .post(`${process.env.REACT_APP_API_URL_CONTACTS}`, newContact, {
          headers: {
            "fortico-access-token": localStorage.getItem(
              "fortico-access-token"
            ), //{"Authorization" : `Bearer ${token}`} }
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          // withCredentials: true,
        })
        .then((response) => {
          let updatedState = [...state.contacts];
          updatedState.push(response.data);
          setstate((previousState) => {
            return {
              ...previousState,
              contacts: [...updatedState],
            };
          });
        });
    } else {
      let updatedState = [...state.contacts];
      newContact.id = uuidv4();
      updatedState.push(newContact);
      setstate((previousState) => {
        return {
          ...previousState,
          contacts: [...updatedState],
        };
      });
    }
  }

  // This function updates an existing contact with the provided details
  function updateContact(contactId) {
    let id = contactId;
    if (isLoggedIn()) {
      const contact = state.contacts.filter((el) => el.id === id)[0];
      const newContact = {
        id: contact.id,
        email: contact.email,
        name: contact.name,
      };
      axios.put(`${process.env.REACT_APP_API_URL_CONTACTS}`, newContact, {
        headers: {
          "fortico-access-token": localStorage.getItem("fortico-access-token"), //{"Authorization" : `Bearer ${token}`} }
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        // withCredentials: true,
      });
    }
  }

  // This function deletes an existing contact
  function deleteContact(e, contactId) {
    let id = contactId;
    if (isLoggedIn()) {
      axios
        .delete(`${process.env.REACT_APP_API_URL_CONTACTS}`, {
          headers: {
            "fortico-access-token": localStorage.getItem(
              "fortico-access-token"
            ), //{"Authorization" : `Bearer ${token}`} }
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          // withCredentials: true,
          data: {
            id: `${id}`,
          },
        })
        .then((response) => {
          setstate((previousState) => {
            return {
              ...previousState,
              contacts: previousState.contacts.filter((el) => el.id !== id),
            };
          });
        });
    } else {
      setstate((previousState) => {
        return {
          ...previousState,
          contacts: previousState.contacts.filter((el) => el.id !== id),
        };
      });
    }
  }

  useEffect(() => {
    listContacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty array means "only run on the first render"

  const handleChange = (e, contactId) => {
    let id = contactId;
    let { name, value } = e.target;

    let updatedState = [...state.contacts];
    let index = updatedState.findIndex((element) => {
      if (element.id === id) {
        return true;
      }
      return false;
    });

    updatedState[index][name] = value;

    setstate((previousState) => {
      return {
        ...previousState,
        contacts: [...updatedState],
      };
    });

    updateContact(contactId);
  };

  if (!state) return null;

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Delete</th>
            {/* <th>Update</th> */}
          </tr>
        </thead>
        <tbody>
          {state.contacts &&
            state.contacts.length > 0 &&
            state.contacts.map((contact, index) => (
              <tr key={contact.id}>
                <td>
                  <input
                    type="text"
                    name="name"
                    value={contact.name}
                    onChange={(e) => handleChange(e, contact.id)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name="email"
                    value={contact.email}
                    onChange={(e) => handleChange(e, contact.id)}
                  />
                </td>
                <td>
                  <button onClick={(e) => deleteContact(e, contact.id)}>
                    Delete
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <form name="createContact">
        <div />
        <input type="text" name="name" />
        <input type="text" name="email" />
        <button type="submit" onClick={(e) => createContact(e)}>
          Create
        </button>
      </form>
      <button onClick={(e) => createRandomContact(e)}>
        Create Random Contact
      </button>
    </div>
  );
}
