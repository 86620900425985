//file: src/webpages/profile.js
import React, { useState, useEffect } from "react";
const axios = require("axios");

const initialState = {
  loggedIn: false,
  username: "",
  authURL: "",
  authLabel: "",
};

function Profile() {
  const [state, setstate] = useState(initialState);

  useEffect(() => {
    document.title = "Fortico - Profile";
  });

  // Helper function
  function isLoggedIn() {
    return localStorage.getItem("fortico-access-token") && true;
  }

  // On load, obtain the config from the server
  const getConfig = () => {
    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    if (isLoggedIn()) {
      headers["fortico-access-token"] = localStorage.getItem(
        "fortico-access-token"
      );
    }
    axios
      .get(process.env.REACT_APP_API_URL_CONFIG, {
        headers: headers,
      })
      .then(function (response) {
        return response.data;
      })
      .then(function (rawdata) {
        let loggedIn = isLoggedIn();
        let username = rawdata.username;
        let authURL = `${process.env.REACT_APP_API_URL_LOGIN}`;
        let authLabel = "login";
        if (rawdata.hasToken === true) {
          authURL = `${
            process.env.REACT_APP_API_URL_LOGOUT
          }?access_token=${localStorage.getItem("fortico-access-token")}`;
          authLabel = "logout";
        }
        setstate((previousState) => {
          return {
            ...previousState,
            loggedIn,
            username,
            authURL,
            authLabel,
          };
        });
      });
  };

  useEffect(() => {
    getConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty array means "only run on the first render"

  return (
    <div>
      <h1>Fortico</h1>

      <p>This is the profile page</p>

      {state.loggedIn === true ? <div>Hello, {state.username}</div> : null}

      <div className="OauthProvider">
        Google:
        <a href={state.authURL}>
          <button>{state.authLabel}</button>
        </a>
      </div>
    </div>
  );
}
export default Profile;
