//file: src/webpages/maps.js
import React, { useEffect } from "react";

function Maps() {
  useEffect(() => {
    document.title = "Fortico - Chat";
  });

  return (
    <div>
      <h1>Fortico</h1>
      <p>This is the maps page</p>
    </div>
  );
}
export default Maps;
