//file: src/webpages/chat.js
import React, { useEffect } from "react";

function Chat() {
  useEffect(() => {
    document.title = "Fortico - Chat";
  });

  return (
    <div>
      <h1>Fortico</h1>
      <p>This is the chat page</p>
    </div>
  );
}
export default Chat;
